import '../Style/Style.css'
import Scroller from "../Scroller";
import './Splash.css'
import Portfolio from "../Portfolio";
import AboutSection from '../About Section';
import { AuthContext } from '../../context/AuthContext';
import { useContext } from 'react';
import TimelinePage from '../Timeline Page';

const Splash = () => {
    const { isAuthenticated } = useContext(AuthContext);
    return (
        <>
            {isAuthenticated ? <TimelinePage /> : null}
            <Scroller />
            <AboutSection />
            <Portfolio />
        </>
    )
}

export default Splash
