import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NavBar from './components/NavBar';
import Splash from './components/Splash Page';
import Footer from './components/Footer';
import ResumePage from './components/Resume Page';
import ProjectPage from './components/Project Page';
import { AuthProvider } from "./context/AuthContext";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
      <NavBar />
        <Switch>
          <Route path='/resume' exact={true}>
            <ResumePage />
          </Route>
          <Route path='/' exact={true}>
            <Splash />
          </Route>
          <Route path='/:projectUrl' exact={true}>
            <ProjectPage />
          </Route>
        </Switch>
      </AuthProvider>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
