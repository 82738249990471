import React, { useState, useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import AdbIcon from "@mui/icons-material/Adb";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import sha256 from "crypto-js/sha256";
import { AuthContext } from "../../context/AuthContext";

import "./NavBar.css";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { dispatch } = useContext(AuthContext);

  const hardcodedUsername = "a";
  const hardcodedPasswordHash =
    "6fb5cdf485c1c72d6a890b54599248903bc22e53548736bef35034e93b632d8d"; // Hash for "password"

  const handleLogin = () => {
    const hashedInputPassword = sha256(password).toString();

    if (username === hardcodedUsername && hashedInputPassword === hardcodedPasswordHash) {
      dispatch({ type: "LOGIN" }); // Update context state
      setOpen(false);
    } else {
      alert("Invalid username or password.");
    }
  };

  return (
    <div className="navbar-container">
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            {/* Logo and title */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
              <Typography
                variant="h5"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                JORDAN BOHMBACH
              </Typography>
            </Box>

            {/* Login Button */}
            <Button color="inherit" onClick={() => setOpen(true)}>
              Login
            </Button>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Login Modal */}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" gutterBottom>
            Login
          </Typography>
          <TextField
            label="Username"
            fullWidth
            variant="outlined"
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }} onClick={handleLogin}>
            Login
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default NavBar;
